import Vue from 'vue'
import VueRouter from 'vue-router'
import PostList from '../components/PostList.vue'
import PostItem from '../components/PostItem.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/page/1'
  }, {
    path: '/page/:page',
    name: 'PostList',
    props: true,
    component: PostList
  }, {
    path: '/posts/:id',
    name: 'PostItem',
    props: true,
    component: PostItem
  }, {
    path: '*',
    beforeEnter() {
      window.location.href='/page/1'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
