<template>
  <v-container>
    <masonry :gutter="{ default: '30px', 700: '15px' }"
             :cols="{ default: 6, 1000: 4, 700: 2, 500: 1 }"
             :if="processedPosts.length > 0">
      <v-card v-for="post in processedPosts"
              :key="post.id"
              style="margin-bottom: 30px"
              min-height="post.thumbnail_height">
        <router-link :to="{ name: 'PostItem', params: { id: post.id } }">
          <v-img :src="post.thumbnail_url"
                 :min-height="post.thumbnail_height"></v-img>
        </router-link>
      </v-card>
    </masonry>
    <v-pagination :length="pagination.total_pages"
                  :value="pagination.current"
                  :if="pagination.total_pages > 0"
                  v-model="pagination.current"
                  @input="navigateToPage"
                  total-visible=7>
    </v-pagination>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'PostList',
    props: ['page'],
    data: function() {
      return {
        results: [],
        pagination: {}
      }
    },
    components: { },
    beforeRouteUpdate(to, from, next) {
      this.getPosts(to.params.page);
      next();
    },
    mounted () {
      this.getPosts();
    },
    methods: {
      navigateToPage(page) {
        this.$router.push({ params: { page: page } });
      },
      async getPosts(page = null) {
        let thePage = null;
        if (page == null) {
          thePage = this.page;
        } else {
          thePage = page;
        }
        let url = process.env.VUE_APP_TG_API_PROTOCOL +
                  process.env.VUE_APP_TG_API_DOMAIN + '/' +
                  process.env.VUE_APP_TG_API_POSTS_PATH + '?page=' + thePage
        axios.get(url).then((response) => {
          this.results = response.data.posts;
          this.pagination = response.data.meta;
          this.$forceUpdate();
        }).catch( error => { console.log(error); });
      }
    },
    computed: {
      processedPosts() {
        let posts = this.results;
        posts.map(post => {
          if (post.images[0].small_thumbnail) {
            post.thumbnail_url    = post.images[0].small_thumbnail.asset_url;
            post.thumbnail_width  = post.images[0].small_thumbnail.width;
            post.thumbnail_height = post.images[0].small_thumbnail.height;
          }
        });
        return posts;
      }
    }
  }
</script>
