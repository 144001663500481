<template>
  <v-app>
    <v-app-bar app>
      <v-container>
        <v-toolbar-title><button @click='$router.push("/")'>thesegirlz.com</button></v-toolbar-title>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>
