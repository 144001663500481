<template>
  <v-container>
    <h2 v-html="processedPost.title"></h2>
        <div v-for="image in processedPost.images"
             v-bind:key="image.id">
          <img class="card-img-top rounded-bottom"
               v-bind:src="image.big_thumbnail.asset_url">
          <br /><br />
        </div>
      <p v-html="processedPost.body"></p>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "PostItem",
    props: ["id"],
    data: function() {
      return {
        result: []
      }
    },
    mounted () {
      this.getPost();
    },
    methods: {
      async getPost() {
        let url = process.env.VUE_APP_TG_API_PROTOCOL +
                  process.env.VUE_APP_TG_API_DOMAIN + '/' +
                  process.env.VUE_APP_TG_API_POSTS_PATH + '/' +
                  this.id
        axios.get(url).then((response) => {
          this.result = response.data;
        }).catch(error => {
          console.log(error);
          this.$router.push('/');
        });
      }
    },
    computed: {
      processedPost() {
        let post = this.result;
        return post;
      }
    }
  }
</script>
